<template>
  <component :is="tag" v-bind="$attrs" :id="identifier" @click="generate">
    <slot>Download {{ fileName }}</slot>
  </component>
</template>

<script>
  import * as XLSX from 'xlsx/xlsx.mjs';
  import { VBtn } from 'vuetify/lib';

  export default {
    name: 'json-export',
    components: {
      VBtn,
    },
    props: {
      type: {
        type: String,
        default: 'xlsx', // mime [xlsx, csv]
      },
      data: {
        type: Array,
        required: false,
        default: null, // Json to download
      },
      fields: {
        type: Object,
        required: false,
        default: null, // fields inside the Data that you want to export
      },
      exportFields: {
        type: Object,
        required: false,
        default: null, // exportFields works exactly like fields
      },
      defaultValue: {
        type: String,
        required: false,
        default: '', // fallback when the row has no field value
      },
      title: {
        default: null, // Title(s) for the data, could be a string or an array
      },
      footer: {
        default: null, // Footer(s) for the data, could be a string or an array
      },
      name: {
        type: String,
        default: 'export_data', // filename to export
      },
      fetch: {
        type: Function,
      },
      meta: {
        type: Array,
        default: () => [],
      },
      worksheet: {
        type: String,
        default: 'Sheet1',
      },
      beforeGenerate: {
        // event before generate was called
        type: Function,
      },
      beforeFinish: {
        // event before download pops up
        type: Function,
      },
      afterFinish: {
        // event after download pops up
        type: Function,
      },
      tag: {
        type: String,
        default: 'div',
      },
    },
    computed: {
      identifier() {
        return `export_${Date.now()}`;
      },

      downloadFields() {
        return this.fields || this.exportFields || null;
      },

      fileName() {
        if (this.type === 'csv') {
          return `${this.name}.csv`;
        }

        return `${this.name}.xlsx`;
      },
    },
    methods: {
      async generate() {
        if (typeof this.beforeGenerate === 'function') await this.beforeGenerate();

        let { data } = this;
        if (typeof this.fetch === 'function' || !data) data = await this.fetch();

        if (!data || !data.length) return;

        const json = this.getProcessedJson(data, this.downloadFields);

        if (typeof this.beforeFinish === 'function') await this.beforeFinish();
        this.newExport(json);
        if (typeof this.afterFinish === 'function') this.afterFinish();
      },

      newExport(data) {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Table');

        return XLSX.writeFile(wb, this.fileName, { bookType: this.type });
      },

      getProcessedJson(data, header) {
        const self = this;
        const keys = this.getKeys(data, header);
        const newData = [];

        data.forEach(item => {
          const newItem = {};

          Object.keys(keys || {}).forEach(label => {
            const property = keys[label];
            newItem[label] = self.getValue(property, item);
          });
          newData.push(newItem);
        });

        return newData;
      },

      getKeys(data, header) {
        if (header) return header;

        const keys = {};
        Object.keys(data[0] || {}).forEach(key => {
          keys[key] = key;
        });
        return keys;
      },

      getValue(key, item) {
        const field = typeof key !== 'object' ? key : key.field;
        const indexes = typeof field !== 'string' ? [] : field.split('.');
        let value = this.defaultValue;

        if (!field) {
          value = item;
        } else if (indexes.length > 1) {
          value = this.getValueFromNestedItem(item, indexes);
        } else {
          value = this.parseValue(item[field]);
        }

        // eslint-disable-next-line no-prototype-builtins
        if (key.hasOwnProperty('callback')) {
          value = this.getValueFromCallback(value, key.callback);
        }

        return value;
      },

      /* ? */
      getValueFromNestedItem(item, indexes) {
        let nestedItem = item;
        Object.keys(indexes || {}).forEach(index => {
          if (nestedItem) nestedItem = nestedItem[index];
        });
        return this.parseValue(nestedItem);
      },

      getValueFromCallback(item, callback) {
        return typeof callback !== 'function' ? this.defaultValue : this.parseValue(callback(item));
      },

      parseValue(value) {
        return value || value === 0 || typeof value === 'boolean' ? value : this.defaultValue;
      },
    },
  };
</script>
